<template>
    <v-container fluid>
            <v-row v-if="false">
                <v-col cols="12" sm="12" class="text-center">
                        <v-btn :to="{name: 'entrance_auto'}" color="indigo" dark block>
                        {{$t('list_of_current_car_inspections') }}
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="12" class="text-center" >
                    <v-btn :to="{name: 'entrance_railway'}" color="indigo" dark block>
                        {{$t('list_of_current_inspections_by_railway') }}
                    </v-btn>
                </v-col>
            </v-row>

        <v-row>
            <v-col class="text-center mt-15" sm="12" cols="12">
                {{$t('please_select_type_of_transport_when_arriving_at_terminal')}}
            </v-col>
            <v-col class="text-center" sm="12" cols="12">
                <v-card slot="footer" key="footer" class="d-inline-block mx-10 my-15 cursor-pointer"
                        style="vertical-align: top;" outlined elevation="3" width="200" height="200"

                        v-for="(entrance, i) in entrances"
                        :key="i"
                >
                    <v-container class="fill-height py-10 justify-center"  @click="getEntrance(entrance)">
                        <v-icon > {{ entrance.slug === 'entrance_auto' ? 'mdi-truck-plus-outline' :'mdi-train'}}  mdi-48px</v-icon>
                            <v-card-subtitle> {{ entrance.name }}</v-card-subtitle>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-overlay :value="loading" z-index="10" color="primary">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex"


export default {
    name: "Entrances",


    data() {
        return {

            options: {},
            loading: false,
            entrances: [],

        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        this.getEntrances();
    },

    methods: {
        getEntrance(item) {
            this.$router.push({
                name: item.slug,
                params: {
                     id: item.uuid
                }
            })
        },
        async getEntrances() {
            this.loading = true;
            let params = {};

            await this.$http
                .get("partner/container_type_receipt", {
                    params: params,
                })
                .then(res => {
                    this.entrances = res.body.data
                })
                .catch(err => {
                    this.entrances = []
                    this.$toastr.error(this.$t('failed_to_get_list_entrances'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
